import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

// Banner image 
import banner1Small from '../Assets/BannerImage/Mobile-banner-01.png';
import banner1Large from '../Assets/BannerImage/Banner-1.jpg';

const Slideshow = () => {
  return (
    <>
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        speed={4000}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            return `<span class="${className} custom swiper-pagination-bullet" style="--swiper-pagination-color: #ffbd00;"></span>`;
          },
        }}
      >
        <SwiperSlide>
          <div className="relative h-full w-full">
            <picture>
              <source media="(max-width: 480px)" srcSet={banner1Small} />
              <source media="(min-width: 481px)" srcSet={banner1Large} />
              <img src={banner1Large} alt="Slide 1" className="w-full md:h-auto h-[480px] object-cover" />
            </picture>
            <div className="absolute md:bottom-10 bottom-10 md:left-1/2 md:transform md:-translate-x-1/2 w-full text-center md:p-6">
              <h2 className="md:text-5xl text-3xl uppercase font-extrabold text-[#ffbd00]">
                Runners up
              </h2>
              <h3 className="md:text-4xl text-2xl uppercase text-white font-semibold md:whitespace-nowrap w-full md:px-3">
                Rupay Prime Volley League Season 3
              </h3>
              <a href='https://delhitoofans.com/' target='_blank'>
                <button className="rounded-lg mt-3 font-bold md:px-6 md:py-2 bg-[#ffbd00] px-2 py-1">
                  Visit Now
                </button>
              </a>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  )
}

export default Slideshow