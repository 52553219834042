import './App.css';
import Slideshow from './Components/Banner/Slideshow';

function App() {
  return (
    <div className="App">
      <Slideshow/>
    </div>
  );
}

export default App;
